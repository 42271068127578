$primary: #971b1e;
$brand-primary: #971b1e;
$brand-primary-lighter: #b82125;
$brand-secondary: #e5e9f2;
$footer-background-color: #1f2d3d;
$footer-secondary-text-color: #8492a6;
$rent-special-bg-color: #fff;

// Screen widths
$xs-screen-size-start: 0;
$xs-screen-size-end: 767px;
$sm-screen-size-start: 768px;
$sm-screen-size-end: 991px;
$md-screen-size-start: 992px;
$md-screen-size-end: 1199px;
$lg-screen-size-start: 1200px;
$xl-screen-size-start: 1800px;

$property-overlay-max-width: 1024px;
$property-page-contact-form-width: 280px;
$property-page-contact-form-margin-right: 15px;
$property-page-headline-section-height: 330px;

$search-grid-image-height: 200px;

// Font Weights
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-extra-bold: 700;
$campus-search-pagination-font-weight: 400;

// New color palette
$light-pink: #ff7ce5;
$pink: #ff49db;
$dark-pink: #ff16d1;
$light-red: #dc393d;
$red: #b82125;
$dark-red: #971b1e;
$light-orange: #ff9e7c;
$orange: #ff7849;
$dark-orange: #ff5216;
$light-yellow: #ffc700;
$yellow: #ffc82c;
$dark-yellow: #f8b700;
$light-green: #29eb7f;
$green: #709993;
$dark-green: #0f9f4f;
$mge-green: #416f5f;
$light-blue: #85d7ff;
$light-blue-highlight: #e7f4fc;
$blue: #1fb6ff;
$dark-blue: #009eeb;
$light-purple: #2d00b6;
$purple: #1f007b;
$dark-purple: #100040;
$marbel-blue: #1fb6ff;

$teal: #297d6d;
$coal: #1f2d3d;
$steel: #273444;
$slate: #3c4858;
$silver: #67778e;
$smoke: #e0e6ed;
$dark-smoke: #d3dce6;
$extra-dark-smoke: #c0ccda;
$snow: #f9fafc;
$dark-snow: #eff2f7;
$extra-dark-snow: #e5e9f2;

$seafoam-green: #a4d1ce;
$forest-green: #709993;
$coral-orange: #dd9a83;
$solid-pink: #ab717f;
$pacific-blue: #79b8dc;
$lavender: #9686cb;
$dark-plum: #605871;
$plum: #886488;
$steel-blue: #7b95a1;
$eggshell: #fdf9ed;

$black: #000;
$white: #fff;

$grid-gutter-width: 12px;

$pricing-tier-icon-height: 15px;
$brand-success: $green;

$platinum-pricing-tier-color: #e5e4e2;
$linear-gradient-fade-middle-color: #69122a;

$search-grid-secondary-text-color: #82888a;

$btn-primary-border: $brand-primary;

$brand-fill: #efcdcd;
$brand-light: #c16c6c;

// As a reminder for the next step, these additional bootstrap variables should be evaluated,
// along with th rest of the default styles in _variables.scss in the boostrap-sass gem

//$gray-base:              #000 !default;
//$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
//$gray-dark:              lighten($gray-base, 20%) !default;   // #333
//$gray:                   lighten($gray-base, 33.5%) !default; // #555
//$gray-light:             lighten($gray-base, 46.7%) !default; // #777
//$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee
//
//$brand-success:         #5cb85c !default;
//$brand-info:            #5bc0de !default;
//$brand-warning:         #f0ad4e !default;
//$brand-danger:          #d9534f !default;

$dark-text: $steel;
$light-dark-text: $silver;
$intelligence-page-background: $dark-snow;
$intelligence-page-light-background: $snow;

// Materialize colors from serach page
$search-radio-empty-color: #dedede;
$search-radio-fill-color: $brand-primary;
//// Colors to indicated disabled
$search-input-disabled-color: #ededed;
$search-input-disabled-solid-color: #ededed;
//// Colors for the filled-in checkbox
$search-input-bg-color: $brand-primary;
$search-secondary-color: #404040;
// End Materialize colors from serach page

$abodo-background-grey: #f2f2f2; // $dark-snow -- remove non background usages, rename to grey-background
$abodo-secondary-green: #1b9756; // $dark-green -- remove var
$abodo-secondary-orange: #f79321; // No good match
$abodo-secondary-orange-hover: #e88b20; // No good match

$abodo-yellow: #ebc535; // $yellow -- remove var
$abodo-light-yellow: #ffc; // No good match, only used in one place
$abodo-blue: #4991e3; // $dark-blue  -- remove var
$abodo-light-gray: #b8b8b8; // $extra-dark-smoke  -- remove var
$abodo-very-light-gray: #e6e6e6; // $smoke -- remove var -- mostly borders, maybe change those to $border-color
$warning-red: #f00; // $light-red  -- consider removing var?

$abodo-black: #262626; // $coal -- remove var, change text usages to $black-text
$abodo-white-text: #f7f7f7; // $snow -- remove var, mostly backgrounds, consider rename to light-gray-background
$abodo-white-background: #f7f7f7; // $snow
$abodo-white-border: #f7f7f7; // $snow
$abodo-hover-white: #cacaca; // $extra-dark-smoke -- rename as modal-close-hover
$abodo-dark-grey-text: #3c4858;
$abodo-hr-color: #eee; // $extra-dark-snow -- Keep var, just reference other var, one usage looks like not an hr, rename just to $hr-color
$abodo-modal-background-color: rgba(7, 0, 28, 0.8); // No good match...
//$abodo-overlayed-text-background-color: transparentize($dark-purple, 0.15);

$abodo-border-color: #ccc; // $dark-smoke Keep, remove non border usages, rename just to $border-color

$facebook-blue-color: #3b5998; // Keep
$facebook-blue-color-hover: #506ec0; // Keep

$light-grey: #ebebeb; // $extra-dark-snow  -- remove var
$medium-grey: #ddd; // $dark-smoke  -- remove var
$medium-dark-grey: #999; // $silver  -- remove var, change color usage to $gray-text
$filter-property-counts: #989898; // $silver  -- remove var, change to $gray-text
$input-background: #f8f8f8; // $snow  -- Remove var
$input-focus: #e9f2f9; //$extra-dark-snow -- Remove var

$black-text: #4a4a4a; // $slate -- switch to $coal as new dark-text color
$gray-text: #9b9b9b; // $silver -- keep var

$slideshow-bg-color: #1a1a1a; //$coal -- keep var

$unavailable-floorplan-text-color: #b7b7b7; // $silver -- change to gray-text

$free-marker-z-index: 1;
$paid-marker-z-index: 2;
$vacancy-killer-marker-z-index: 3;
$hover-marker-z-index: 4;
$after-lead-mobile-spinner-z-index: 1051;
$lead-confirmation-modal-z-index: 1052;
$search-loading-screen-z-index: 2;
$fullscreen-search-loading-screen-z-index: 1051;
$phone-screen-media-z-index: 2;

$rounded-corners: 2px;
$navbar-height: 52px;
$footer-height: 115px;

$map-list-holder-background-color: $white;
$grid-filters-background-color: $abodo-white-background; // $dark-snow -- remove var

$mobile-google-places-results-padding-left: 15px;
$mobile-google-places-autocomplete-container-top: 60px;
$google-places-autocomplete-color: #999; // $silver -- remove var

$field-box-shadow: #e0e0e0; // $smoke -- keep var
$light-box-shadow: #e2e2e2; // $smoke -- change to $field-box-shadow

$amenity-icon-size: 22px;

// 2 to be higher than the verified badge
// (referencing design with check availability and photos & details buttons)
$search-filters-z-index: 3;

// media
$media-type-overlay-size: 70px; // used for any overlay that describes video/matterport media

$review-photo-offset: 53px;

$floorplan-tabs-bg-color-active: $white;
$floorplan-tabs-border-color: #ddd; // $dark-smoke -- change to standard border color
$floorplan-tabs-border-color-hover: #eee; // $extra-dark-snow -- consider change to standard border color

//$brown-cta-background: transparentize($abodo-secondary-orange, 0.4);

$search-filters-slider-border-color: #752f30;
$transparent-white-7: rgba(255, 255, 255, 0.7);
$transparent-black-5: rgba(0, 0, 0, 0.5);

//borders
$border-353434: #353434;
$border-428bca: #428bca;
$border-5aa1ff: #5aa1ff;
$border-808080: #808080;
$border-999: #999;
$border-adabab: #adabab;
$border-b6b6b6: #b6b6b6;
$border-b8b8b8: #b8b8b8;
$border-c00: #c00;
$border-c0c0c0: #c0c0c0;
$border-d3d3d3: #d3d3d3;
$border-d7d9de: #d7d9de;
$border-d9d9d9: #d9d9d9;
$border-dcdcdc: #dcdcdc;
$border-dce0e0: #dce0e0;
$border-ddd: #ddd;
$border-e8e8e8: #e8e8e8;
$border-e9e9e9: #e9e9e9;
$border-efefef: #efefef;
$border-f2f2f2: #f2f2f2;
$border-fafafa: #fafafa;

//backgrounds
$background-0097cf: #0097cf;
$background-111: #111;
$background-13004c: #13004c;
$background-262626: #262626;
$background-404040: #404040;
$background-5aa1ff: #5aa1ff;
$background-81171a: #81171a;
$background-a9a9a9: #a9a9a9;
$background-c0c0c0: #c0c0c0;
$background-cecece: #cecece;
$background-d8d8d8: #d8d8d8;
$background-e1e1e1: #e1e1e1;
$background-e5e5e5: #e5e5e5;
$background-edafa9: #edafa9;
$background-ededed: #ededed;
$background-edf4b8: #edf4b8;
$background-eee: #eee;
$background-efefef: #efefef;
$background-f00: #f00;
$background-f0f0f0: #f0f0f0;
$background-f8f5f5: #f8f5f5;
$background-fafafa: #fafafa;
$background-ffa500: #ffa500;

//text
$color-666: #666;
$text-007bf8: #007bf8;
$text-008000: #008000;
$text-009245: #009245;
$text-333: #333;
$text-3b0b38: #3b0b38;
$text-414141: #414141;
$text-424242: #424242;
$text-428bca: #428bca;
$text-555: #555;
$text-5cb85c: #5cb85c;
$text-777: #777;
$text-808080: #808080;
$text-868686: #868686;
$text-8f8686: #8f8686;
$text-909090: #909090;
$text-999: #999;
$text-a9a9a9: #a9a9a9;
$text-aaa: #aaa;
$text-aeaeae: #aeaeae;
$text-b4b4b4: #b4b4b4;
$text-bbb: #bbb;
$text-bebebe: #bebebe;
$text-c0c0c0: #c0c0c0;
$text-c5c5c5: #c5c5c5;
$text-c8c8c8: #c8c8c8;
$text-c9c9c9: #c9c9c9;
$text-d1d1d1: #d1d1d1;
$text-d3d3d3: #d3d3d3;
$text-efefef: #efefef;
$text-f00: #f00;
$text-fafafa: #fafafa;
$text-fc0: #fc0;
$text-ffa500: #ffa500;
$text-ffd500: #ffd500;

$gradient-base-1f1f1f: #1f1f1f;

//shadow
$shadow-161616: #161616;
$shadow-424242: #424242;
$shadow-595959: #595959;
$shadow-cecece: #cecece;

//stroke
$stroke-424242: #424242;

// font-awesome
$fa-var-angle-down: "\f107";
$fa-var-angle-left: "\f104";
$fa-var-angle-right: "\F105";
$fa-var-check-square: "\f14a";
$fa-var-chevron-left: "\f053";
$fa-var-chevron-right: "\f054";
$fa-var-search: "\f002";
$fa-var-sort-desc: "\f0dd";
$fa-var-square-o: "\f096";
